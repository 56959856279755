<template>
    <div class="main login-form register">
        <div class="page-content">
            <ShopInfo />

            <div class="inner" v-if="Common.isExist(config)">
                <div class="title">會員註冊</div>
                <el-form ref="user" :model="user">
                    <el-form-item
                        prop="memEmail"
                        :rules="[
                            {
                                validator: chkAcctExist,
                                trigger: 'blur',
                                required: true,
                            },
                        ]"
                    >
                        <el-input placeholder="電子信箱帳號" v-model="user.memEmail" />
                    </el-form-item>
                    <el-form-item
                        prop="memName"
                        :rules="[
                            {
                                trigger: 'blur',
                                required: true,
                                message: msg.req,
                            },
                        ]"
                    >
                        <el-input placeholder="姓名" v-model="user.memName" />
                    </el-form-item>
                    <el-form-item
                        prop="passwd"
                        :rules="[
                            {
                                validator: msg.eleIsPassword,
                                trigger: 'blur',
                                required: true,
                                message: msg.password,
                            },
                        ]"
                    >
                        <el-input show-password placeholder="密碼" v-model="user.passwd" />
                    </el-form-item>
                    <el-form-item
                        prop="password2"
                        :rules="[
                            {
                                validator: isPassword2,
                                trigger: 'blur',
                                required: true,
                            },
                        ]"
                    >
                        <el-input show-password placeholder="確認密碼" v-model="user.password2" />
                    </el-form-item>
                    <el-checkbox class="agreement" v-model="agreement">
                        <span class="agreement-content">
                            我已閱讀並同意
                            <a :href="config.privacyTerm" target="_blank" :style="{ color: config.color }">服務協議</a>
                            與
                            <a :href="config.serviceTerm" target="_blank" :style="{ color: config.color }">隱私政策</a>
                        </span>
                    </el-checkbox>
                    <div class="btn-grp">
                        <el-button type="primary" :disabled="!agreement" @click="onRegister">
                            立即註冊
                        </el-button>
                    </div>
                    <div class="login-form-operate" :style="{ color: config.color }">
                        <router-link to="/account/login" replace>立即登入</router-link>
                        <router-link to="/account/lookup">忘記密碼</router-link>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { Form, FormItem, Input, Button, Checkbox } from "element-ui";
import { mapState, mapActions } from "vuex";
import { ShopInfo } from "@/components";
export default {
    name: "Register",
    components: {
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Input.name]: Input,
        ShopInfo,
    },
    data() {
        return {
            user: {
                memName: "",
                memEmail: "",
                passwd: "",
                password2: "",
            }, //表單驗證信息
            agreement: false,
            info: {
                // color: "#90BE40",
            },
            list: [],
            success: false,
            isExist: false, //電子信箱是否存在
        };
    },
    watch: {},
    computed: {
        ...mapState({
            //validateMsg
            msg(state) {
                return state.validate;
            },
            config(state) {
                return state.base.configInfo;
            },
        }),
    },
    watch: {},
    created() {},
    methods: {
        //註冊
        async onRegister(val) {
            this.$refs.user.validate((valid) => {
                if (valid) {
                    const obj = {
                        type: "onRegister",
                        val: { ...this.user },
                    };
                    this.$store.dispatch("base/setVerifySource", obj);
                } else {
                    return false;
                }
            });
        },
        //密碼二次檢驗
        isPassword2(rule, value, callback) {
            if (value === "") {
                callback(new Error("請再次輸入密碼"));
            } else if (value !== this.user.passwd) {
                callback(new Error("兩次輸入的密碼不一致"));
            } else {
                callback();
            }
        },
        //驗證帳號是否存在
        async chkAcctExist(rule, value, callback) {
            const reg = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*\.[_a-z0-9-]+(\.[_a-z0-9-]+)*$/i;
            if (!reg.test(this.user.memEmail || !this.user.memEmail)) {
                callback(new Error("電子信箱格式不正確"));
                return false;
            }

            const obj = {
                type: "chkAcctExist",
                val: { memEmail: this.user.memEmail },
            };
            await this.$store.dispatch("base/setVerifySource", obj).then((isExist) => {
                console.log(isExist);
                if (isExist) {
                    callback(new Error("此帳號已被使用，請輸入其他帳號"));
                }
            });
        },
    },
};
</script>
<style lang="scss" src="@/assets/scss/mixin/loginForm.scss"></style>
<style>
.agreement {
    text-align: left;
    width: 100%;
}
</style>
